//
// pricing.scss
//

.btn-primary:hover {
  color: #fff;
  background-color: red;
  border-color: #285e8e; /*set the color you want here*/
}
.termination-box {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: $box-shadow;
  padding: 35px 20px;
  border-radius: 10px;
  position: relative;
  .plan {
    font-size: 34px;
  }
}
.termination-box-disabled{
  opacity:0.3;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: $box-shadow;
  padding: 35px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  .plan {
    font-size: 34px;
  }
}


.div-border-radius{
  border-radius: 10px !important;
}
.pricing-box {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: $box-shadow;
  padding: 35px 20px;
  border-radius: 10px;
  position: relative;
  .plan {
    font-size: 34px;
  }
  img {
    width: 286px;
    height: 350px;
  }
}

.pricing-box-disabled {
  opacity:0.3;
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: $box-shadow;
  padding: 35px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
  .plan {
    font-size: 34px;
  }
  img {
    width: 286px;
    height: 350px;
  }
}
.img-tree{
  width: 1vw;
  height: 1vw;
}
.extra-box {
  background-color: transparent;
  box-shadow: $box-shadow;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 90px;
  border: 2px solid rgba(120, 188, 223, 1);
   padding: 0px 12px 0px 12px;
  .plan {
    font-size: 18px;
  }
  
}
.extra-box-disabled {
  opacity:0.3;
  background-color: transparent;
  box-shadow: $box-shadow;
  padding: 35px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 2px solid rgba(120, 188, 223, 1);

  position: relative;
  .plan {
    font-size: 34px;
  }
  img {
    width: 286px;
    height: 350px;
  }
}


.extra {
  width: 100%;
  border-width: thin;
  background-color: rgba(54, 48, 45, 1);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 2px solid rgba(120, 188, 223, 1);
  border-right: 2px solid rgba(120, 188, 223, 1);
  border-top: 0px;
  border-bottom: 2px solid rgba(120, 188, 223, 1);
}
.extra:disabled {
  opacity: 0.3;
}
.badge-termination {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(120, 188, 223, 1);
  border-radius: 10px;
  padding: 0px 15px 0px 15px;
 
  
}
.btn-type {
  font-weight: 800;
  font-size: 20px;


}
.extra:hover {
  width: 100%;
  border-width: thin;
  background-color: rgba(59, 48, 45, 1);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 2px solid rgba(120, 188, 223, 1);
  border-right: 2px solid rgba(120, 188, 223, 1);
  border-top: 0px;
  border-bottom: 2px solid rgba(120, 188, 223, 1);
}

.add {
  width: 100%;
  background-color: rgba(54, 48, 45, 1);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 3px solid rgba(255, 255, 255, 0.1);
  border-right: 3px solid rgba(255, 255, 255, 0.1);
  border-top: 0px;
  border-bottom: 3px solid rgba(255, 255, 255, 0.1);
}
.add:disabled {
 opacity: 0.3;
}
.add:hover {
  width: 100%;
  background-color: rgba(59, 48, 45, 1);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-left: 3px solid rgba(255, 255, 255, 0.1);
  border-right: 3px solid rgba(255, 255, 255, 0.1);
  border-top: 0px;
  border-bottom: 3px solid rgba(255, 255, 255, 0.1);
}

.iconPlus {
  align-self: center;
}
.iconPlus-selected {
  transform: rotate(45deg);
}
.description {
 padding: 0px 0px 0px 0px !important;
 margin:0px 0px 0px 0px !important;
}
.bg-bluex {
  background-color: #78bcdf;
}
.bg-gris {
  background-color: rgba(54, 48, 45, 1);
}

.pricing-badge {
  position: absolute;
  top: 0;
  z-index: 999;
  right: 0;
  width: 100%;
  display: block;
  font-size: 15px;
  padding: 0;
  overflow: hidden;
  height: 100px;
  .badge {
    float: right;
    transform: rotate(45deg);
    right: -67px;
    top: 17px;
    position: relative;
    text-align: center;
    width: 200px;
    font-size: 13px;
    margin: 0;
    padding: 7px 10px;
    font-weight: 500;
    color: $white;
    background: $danger;
  }
}
