//
// screenshot.scss
//

.bg-services {
  background-image: url(../images/bg-2.png);
}

.icon-wrapper {
  height: 10vh;
}

.services-box {
  min-height: 39vh;
  box-shadow: $box-shadowICon;
  border-radius: 20px;
  border: 1px solid rgba(120, 188, 223, 1);
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    background-color: rgba(120, 188, 223, 0.2);

    border: 1px solid rgba(120, 188, 223, 0.5);
  }
  .services-icon {
    display: flex;
    justify-content: center;

    img {
      object-fit: contain;
      margin-top: 20px;
      width: 100px;
      height: 100px;
    }
  }
}

.bg-features {
  border-radius: 0px 0px 350px 0px;
}

.colorButtonfaq {
  font-size: 19px;
  color: rgba(0, 0, 0, 1);
}

.faq {
  border: 1px solid rgba(120, 188, 223, 1);
  padding-top: 5px;
  background-color: rgba(120, 188, 223, 0.35);
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}
