//
// Footer.scss
//

.bg-footer {
  background-color: rgba(54, 48, 45, 1);
  .contact-img {
    position: absolute;
    margin-top: -280px;
  }
}

@media (max-width: 992px) {

  // Align text to center.
  .xs-center {
    display:flex;
    justify-content:center;
    align-items:center;

  } 
}
.link-style{
  margin-bottom: -40px;
}
.imgFooterS{
  height: 18vh;
}
.water-footer{
  height: 26vh;
}

.imgFooter {
  height: '100%';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.iconFooter {
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.footer-link {
  a {
    color: $muted;
    line-height: 38px;
    font-size: 15px;
    transition: all 0.5s;
    &:hover {
      color: $primary;
    }
  }
}

.subscribe {
  input {
    padding: 12px 20px;
    width: 100%;
    font-size: 15px;
    color: $muted;
    border: none;
    outline: none !important;
    padding-right: 75px;
    padding-left: 15px;
    background-color: rgba($primary, 0.1);
    border-radius: 5px;
  }
  button {
    position: absolute;
    top: 0px;
    right: 0px;
    outline: none !important;
    border-radius: 0px 5px 5px 0px;
    padding: 2px 12px;
    font-size: 25px;
  }
  form {
    position: relative;
    max-width: 400px;
  }
  ::placeholder {
    color: $muted;
  }
}
