//
// responsive.scss
//

@media (min-width: 200px) and (max-width: 1200px) {
  .topbar {
    display: none;
  }

  .navbar-custom {
    margin-top: 0px;
    padding: 15px 0px !important;
    background-color: rgba(54, 48, 45, 1) !important;
    box-shadow: 0 -2px 10px rgba(67, 83, 255, 0.14);
    color: $white !important;
    top: 0;

    .navbar-nav {
      margin-top: 0px;
      li {
        &.active {
          border-color: transparent;
        }
        a {
          transition: all 0.4s;
          color: $white !important;
          margin: 0px;
          padding: 6px 0;
          border-top: none !important;
        }
      }
    }
    > .container {
      width: 90%;
    }
  }

  .navbar-custom .navbar-nav li.active a,
  .navbar-custom .navbar-nav li a:hover,
  .navbar-custom .navbar-nav li a:active {
    color: $white !important;
    border-top: none !important;
  }

  .navbar-toggler {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: $dark;
  }

  .logo {
    .logo-light {
      display: none;
    }
    .logo-dark {
      display: inline-block;
    }
  }

  .home-img {
    .animation-1 {
      right: 0px;
    }
  }

  .carousel-indicators {
    left: 0px;
  }

  .navbar-light {
    .navbar-nav {
      li a.active,
      li a:hover,
      li a:active {
        color: $primary !important;
      }
    }
  }
}
