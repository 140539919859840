.bg-modal{
    background-color:  #36302D; 
    z-index: 9999999999 !important;
}

.item-drawer{
    padding: 10px 10px 10px 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin: 5px 5px 5px 5px;
    background-color: rgba(255, 255, 255, 0.1);
    
}

.added{
    display: flex;
    flex-direction: column;
    justify-items: center;
    margin-top: 20px;


}
.added div{
   padding: 0px 10px 0px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
}
.added div p{ 
   
    font-size: 13px;
   line-height: 19.4px;
   padding: 0 !important;
   margin: 0 !important;
  
}
.item-header{
    display: flex;
    justify-content:space-around;

}


.image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 12vh;
    height: 14vh;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
}
.item-counter-button-styles2{
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0px;
    padding: 2px 8px 2px 8px !important;
    margin: 0px 0px 0px 0px !important;
}
.item-counter-border-left{
    border-radius: 6px 0px 0px 6px !important;
}
.item-counter-border-right{
    border-radius: 0px 6px 6px 0px !important;
}
.item-counter-button-styles{
    background-color:rgba(255, 255, 255, 0.1);
    border-radius: 0px;
    padding: 2px 8px 2px 8px !important;
    margin: 0px 0px 0px 0px !important;
}
.body-wrapper{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content:space-between;
    overflow:scroll;
    overflow-x:hidden;
}
.btn-primary-custom{
    border-radius: 8px;
    border: 0px !important;
}
.btn-secondary-custom{
    border-radius: 8px;
    border: 0px !important;
    background-color: rgba(74, 69, 66, 1) !important;
}
.footer-wrapper{
    padding: 0px 10px 0px 10px;
    display: flex;
    flex-direction: column;

}
.item-counter-container{
    background-color: transparent;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content:right;
    align-items: center;
}
.item-counter-container:active{
    background-color: transparent;
}
.text-description-title{
    line-height: 19px;
    font-size: 16px;
    font-weight: bolder;
}
.text-description{
    line-height: 5px;
    font-size: 12px;
}
.item-description-container{
display: flex;

flex-direction: column;
justify-content:center;
}
.tina-item{
    margin-left: -6px;
    height: 120px;
}
.title-color{
    color: #78BCDF;
}

.contact-container{
    border-radius: 8px;
    
    background-color: rgba(255, 255, 255, 0.1);

}